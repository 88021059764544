class AlarmBg {
  #bgEl;

  #tlAlarmArmed;

  #tlAlarmOff;

  static gsap;

  constructor() {
    this.gsap = AlarmBg.gsap || window.gsap;

    this.#bgEl = document.querySelector('.page-bg img');
    this.#tlAlarmArmed = this.gsap.timeline({ paused: true });
    this.#tlAlarmOff = this.gsap.timeline({ paused: true });
  }

  static registerGSAP(gsap) {
    AlarmBg.gsap = gsap;
  }

  armed() {
    this.#tlAlarmArmed
      .set(this.#bgEl, {
        filter: 'grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(80%)',
      });

    this.#tlAlarmArmed.play();
  }

  off() {
    this.#tlAlarmOff
      .to(this.#bgEl, {
        filter: 'grayscale(0%) brightness(100%) sepia(0%) hue-rotate(0deg) saturate(100%) contrast(100%)',
        delay: 0.6,
        duration: 0.6,
        ease: 'none',
      });

    this.#tlAlarmOff.play();
  }
}

export default AlarmBg;
