class CopyText {
  #copiedEl;

  constructor(options = {}) {
    const selector = typeof options.copiedEl === 'string' ? options.copiedEl : '.copy-text';
    this.#copiedEl = document.querySelector(selector);
  }

  #copyToClipboard(str) {
    const area = document.createElement('textarea');

    area.readOnly = true;
    area.style.cssText = 'position: absolute; left: -9999px; top: 0;';

    document.body.appendChild(area);

    area.value = str;
    area.select();

    document.execCommand('copy');
    document.body.removeChild(area);
  }

  #uiCopyDone() {
    this.#copiedEl.classList.add('copied');

    setTimeout(() => this.#copiedEl.classList.remove('copied'), 1600);
  }

  #setupCopyListener() {
    if (this.#copiedEl && !this.#copiedEl.dataset.copyListenerAdded) {
      this.#copiedEl.addEventListener('click', () => {
        this.copy();
      });

      this.#copiedEl.dataset.copyListenerAdded = 'true';
    }
  }

  copy() {
    if (this.#copiedEl) {
      this.#copyToClipboard(this.#copiedEl.textContent);
      this.#uiCopyDone();
    }
  }

  init() {
    this.#setupCopyListener();
  }
}

export default CopyText;
