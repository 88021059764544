class SafeGame {
  #chest;

  #tlChestSwing;

  #tlChestStop;

  #tlChestSwim;

  #tlChestShadow;

  #tlChestPick;

  #callback;

  static gsap;

  constructor(callback = null) {
    this.#chest = SafeGame.gsap.utils.toArray('.safes-game__item');
    this.#tlChestSwing = SafeGame.gsap.timeline({ paused: true });
    this.#tlChestStop = SafeGame.gsap.timeline({ paused: true });
    this.#tlChestSwim = SafeGame.gsap.timeline({ paused: true });
    this.#tlChestShadow = SafeGame.gsap.timeline();
    this.#tlChestPick = SafeGame.gsap.timeline();

    this.#callback = callback;
  }

  static registerGSAP(gsap) {
    SafeGame.gsap = gsap;
  }

  courseAnimation() {
    this.#tlChestSwing
      .set(this.#chest, {
        rotate: 1.5,
      })
      .to(this.#chest, {
        duration: 2,
        rotate: -2,
        ease: 'power1.inOut',
        yoyo: true,
        repeat: -1,
      });

    this.#tlChestSwim
      .to(this.#chest, {
        ease: 'power1.inOut',
        x: 'random(-5, 5)',
        y: 'random(-5, 5)',
        duration: 2,
        repeat: -1,
        yoyo: true,
        repeatRefresh: true,
      });

    this.#tlChestStop
      .to(this.#chest, {
        rotate: 0,
        duration: 0.5,
        pointerEvents: 'none',
      });

    this.#tlChestSwing.play();
  }

  pickAnimation(clickedChest) {
    this.#tlChestPick
      .fromTo(clickedChest, {
        x: -7,
      }, {
        x: 7,
        repeat: 20,
        duration: 0.03,
        yoyo: true,
      })
      .to(clickedChest, {
        y: -30,
        duration: 0.55,
        ease: 'circ.out',
        onStart: () => {
          setTimeout(() => {
            clickedChest.classList.add('win');

            this.#chest.forEach((i) => {
              if (i !== clickedChest) {
                i.classList.add('loose');
              }
            });
          }, 40);
        },
      }, '-=0.05')
      .to(clickedChest, {
        y: 0,
        duration: 0.25,
        ease: 'circ.in',
        onComplete: () => {
          this.#tlChestSwim.play();
        },
      })
      .set(clickedChest, {
        '--shadow-color': '0',
      })
      .to(clickedChest, {
        duration: 5,
        '--shadow-color': '360',
        ease: 'none',
        repeat: -1,
      });

    return this.#tlChestPick;
  }

  handle(event) {
    const clickedChest = event.target.closest('.safes-game__item');

    if (clickedChest) {
      this.#tlChestSwing.pause();
      this.#tlChestStop.play();
      this.pickAnimation(clickedChest).play();

      if (typeof this.#callback === 'function') {
        this.#callback();
      }
    }
  }

  init() {
    this.courseAnimation();

    document.addEventListener('pointerdown', this.handle.bind(this));
  }
}

export default SafeGame;
