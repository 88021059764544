import MediaTrigger from 'media-trigger';

class LaserBeams {
  #lasersEl;

  #tlLasers;

  #tlLasersOff;

  #media;

  #laserShadowOpacity;

  static gsap;

  constructor() {
    this.gsap = LaserBeams.gsap || window.gsap;

    this.#lasersEl = document.querySelector('.lasers');
    this.#tlLasers = this.gsap.timeline({ paused: true });
    this.#tlLasersOff = this.gsap.timeline({ paused: true });
    this.#media = null;
    this.#laserShadowOpacity = null;

    this.mediaTrigger();
  }

  static registerGSAP(gsap) {
    LaserBeams.gsap = gsap;
  }

  mediaTrigger() {
    const createTrigger = (media, entry, exit, change) => {
      return new MediaTrigger({
        media,
        entry,
        exit,
        change,
      });
    };

    createTrigger(
      // '(min-width: 992px) and (any-pointer: fine)',
      '(min-width: 992px)',
      () => {
        this.#media = true;
      },
      () => {
        this.#media = false;
      },
      () => {
        this.refreshAnimation();
      },
    );
  }

  lasers() {
    this.#tlLasers.clear();

    this.#tlLasers
      .set(this.#lasersEl, {
        '--laser-shadow-opacity': this.#media ? 0.6 : 0.5,
      })
      .to(this.#lasersEl, {
        '--laser-shadow-opacity': 0.9,
        duration: 0.0667,
        repeat: -1,
        yoyo: true,
        ease: 'none',
      });

    this.#tlLasersOff
      .to(this.#lasersEl, {
        '--laser-shadow-opacity': 0.2,
        duration: 0.075,
        repeat: 5,
        repeatDelay: 0.05,
        yoyo: true,
        ease: 'none',
      })
      .to(this.#lasersEl, {
        autoAlpha: 0,
        duration: 0.5,
        ease: 'none',
        onComplete: () => {
          this.#tlLasers.paused();
        },
      });
  }

  refreshAnimation() {
    this.lasers();
    this.#tlLasers.play();
  }

  armed() {
    this.#tlLasers.play();
  }

  off() {
    this.#tlLasersOff.play();
  }
}

export default LaserBeams;
