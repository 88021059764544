class ModalManager {
  #modal;

  #modalClose;

  #modalBackdrop;

  constructor() {
    const { modal, modalClose, modalBackdrop } = this.#getElements();
    this.#modal = modal;
    this.#modalClose = modalClose;
    this.#modalBackdrop = modalBackdrop;
  }

  // eslint-disable-next-line class-methods-use-this
  #getElements() {
    const modal = document.querySelector('.modal');
    const modalClose = document.querySelector('.modal-content__close');
    const modalBackdrop = document.querySelector('.modal__backdrop');

    return { modal, modalClose, modalBackdrop };
  }

  init() {
    [this.#modalClose, this.#modalBackdrop].forEach((element) => {
      if (element) {
        element.addEventListener('click', () => this.close());
      }
    });
  }

  open() {
    if (this.#modal) {
      this.#modal.classList.remove('modal--out');
      this.#modal.classList.add('modal--in');
    }
  }

  close() {
    if (this.#modal) {
      this.#modal.classList.remove('modal--in');
      this.#modal.classList.add('modal--out');
    }
  }
}

export default ModalManager;
