import gsap from 'gsap';
import ClickTone from 'clicktone';
import LaserBeams from './utils/LaserBeams';
import SafeGame from './utils/SafeGame';
import AlarmBg from './utils/AlarmBg';
import ModalManager from './utils/ModalManager';
import CopyText from './utils/CopyText';

// Регистрируем GSAP в классах
LaserBeams.registerGSAP(gsap);
SafeGame.registerGSAP(gsap);
AlarmBg.registerGSAP(gsap);

// Озвучка открывающегося сейфа
const playSound = new ClickTone({
  file: new URL('./assets/audio/chest.mp3', import.meta.url),
  volume: 0.6,
  throttle: 400,
  debug: false,
});

// Modal
const modalWindow = new ModalManager();

// Анимация фона
const alarmBg = new AlarmBg();

// Анимация лазерных лучей
const laserBeams = new LaserBeams();

// Анимация игры с сейфами
const modalEl = document.querySelector('.modal');
const safesEl = document.querySelector('.safes-game');

const safeGame = new SafeGame(() => {
  modalEl.classList.remove('modal--one');
  modalEl.classList.add('modal--two');

  playSound.play().then();

  setTimeout(() => {
    modalWindow.open();
  }, 2150);
});

// Копирование промокода
const copyText = new CopyText({
  copiedEl: '.promo-code',
});

const app = () => {
  laserBeams.armed();
  safeGame.init();
  alarmBg.armed();
  copyText.init();

  safesEl.style.pointerEvents = 'none';

  setTimeout(() => {
    modalEl.classList.add('modal--one');

    safesEl.style.pointerEvents = '';

    modalWindow.open();
  }, 1500);

  document.getElementById('btn-modal-promo').addEventListener('click', () => {
    modalWindow.close();

    setTimeout(() => {
      modalEl.classList.remove('modal--two');
      modalEl.classList.add('modal--three');

      modalWindow.open();

      copyText.copy();
    }, 500);
  });

  document.getElementById('btn-lasers-off').addEventListener('click', () => {
    laserBeams.off();
    modalWindow.close();
    alarmBg.off();
  });
};

document.addEventListener('DOMContentLoaded', () => app());
